import React from "react"
import TextSectionBackground from "../components/TextSectionBackground"
import { SectionHeader, SubSectionHeader } from "./Headings 2"
import styled from "styled-components"
import TentsGrid from "../components/TentsGrid"

const ContentWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding-bottom: 50px;
  p {
    text-align: left;
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    line-height: 1.5;
    &.center {
      text-align: center;
      margin-bottom: 0;
    }
    img {
      cursor: pointer;
      transition: transform 0.5s;
      &:hover {
        transform: scale(1.1);
      }
    }    
  }
`

const TentSection = ({ children }: any) => {
  return (
    
        <TextSectionBackground pa="4% 10%" style={{background:"#ABA17B"}}>
          <SubSectionHeader style={{fontSize:"28px" , color:"white"}}>
            Luxury Tented Accommodation
          </SubSectionHeader>
          <ContentWrapper style={{textAlign:"left" , color:"white"}}>
            <p>
            </p>
          </ContentWrapper>
          <ContentWrapper>
            <TentsGrid></TentsGrid>
          </ContentWrapper>          
        </TextSectionBackground>
  )
}
export { TentSection }