import React from "react"
import { PageLayout } from "../components/Layout"
import { useStaticQuery, graphql, Link } from "gatsby"
import ImageRow from "../components/ImageRow"
import TextSectionBackground from "../components/TextSectionBackground"
import { PageHeader } from "../components/Headings 1"
import { ActionButton, ButtonBasic } from "../components/buttons"
import EnquireButton from "../components/EnquireButton"
import styled from "styled-components"
import SEO from "../components/SEO"
import { TentSection } from "../components/NewLuxuryTents"

const ButtonWrapper = styled.div`
  margin: 2rem 0 2rem -10px;
  button {
    margin: 8px 10px;
  }
`

const Wrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding-bottom: 50px;
  p {
    text-align: left;
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    line-height: 1.5;
    &.center {
      text-align: center;
      margin-bottom: 0;
    }
    img {
      cursor: pointer;
      transition: transform 0.5s;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
`

const Accommodation = () => {
  const data = useStaticQuery(graphql`
    query {
      images: contentfulGallery(name: { eq: "Accommodation" }) {
        images {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <PageLayout view={true}>
      <SEO
        title="Luxury Private Safari Tented Accommodation | Mavela Game Lodge"
        description="Stay in one of seven luxury safari tents with en suite shower facilities and a private deck with beautiful views over the surrounding bushveld."
      />
      <TextSectionBackground pa="5% 7% 2%">
        <PageHeader>Luxury Safari-Style Tents</PageHeader>
        <ImageRow
          pic={data.images.images[1].fluid}
          header="Our Rooms"
          text={
            <>
              Unwind and make yourself at home in one of our seven, luxury
              safari-style tents. Each luxury tent is tastefully and modernly
              decorated to show off the best in safari luxury. Each room is
              complete with aircon, standing fans and luxurious Afri-Earth
              amenities complemented with crisp white linen on either single or
              king beds. There is an en-suite bathroom and shower plus an
              intimate outdoor shower that faces the views of the bushveld,
              which allows full privacy to immerse yourself in the natural
              surroundings. Unwind on a large private deck looking out at the
              beautiful views with comfortable deck chairs and a birdbath in
              front of each tent, which birding enthusiasts can enjoy ticking
              off their lifers from the comfort of their veranda. Or if you’re
              wanting to indulge in a soothing safari yoga workout, we also
              provide yoga mats in each room for you to breathe it out over a
              stunning view. The entire camp is fenced, allowing wildlife to
              roam free through the camp. In the evenings our guests will be
              escorted back to their rooms after dinner, for safety reasons.
              It’s our wish that when you stay at Mavela Game Lodge you’ll feel
              the intimacy of our safari lodge and gain a unique experience of
              what it means to truly live in the luxury of the African bush.
              <ButtonWrapper>
                <EnquireButton />
                <ActionButton>
                  <a
                    href="https://www.nightsbridge.co.za/bridge/book?bbid=16200"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Check Availability
                  </a>
                </ActionButton>
                <ActionButton>
                  <a href="/rates" rel="noopener noreferrer" target="self">
                    Rates
                  </a>
                </ActionButton>
              </ButtonWrapper>
            </>
          }
          richText={false}
        ></ImageRow>
      </TextSectionBackground>
      <TentSection />
    </PageLayout>
  )
}

export default Accommodation
