import React from 'react';
import styled from "styled-components"
import Modal from 'react-modal';
import EnquireForm from "./EnquireForm"
import { ButtonBasic } from "../components/buttons"

const customStyles = {
  content: {
    top: '5%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translateX(-50%)',
    border: 'none',
    borderRadius: '0',
    padding: '0',
    maxHeight: '90vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.7)',
    zIndex: '10'
  },
};

const ModalContent = styled.div`
  width: 1000px;
  background: white;
  position: relative;
  button.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    font-size: 40px;
    color: white;
    border: none;
    padding: 1rem;
    cursor: pointer;
    
    @media all and (max-width: 680px) {
      top: 0;
    }
  }
  @media all and (max-width: 1000px) {
    width: 100%;
  }
`
const EnquireWrapper = styled.div`
  display: inline-block;
`

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement');

const EnquireButton = () => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <EnquireWrapper>
      <ButtonBasic onClick={openModal}>Enquire Now</ButtonBasic>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Enquire Now"
        class="modal-dialog"
      >
        <ModalContent>
          <button onClick={closeModal} class="close-button" aria-label="Close alert" type="button" data-close>
            <span aria-hidden="true">&times;</span>
          </button>
        <EnquireForm />
        </ModalContent>
        
      </Modal>
    </EnquireWrapper>
  );
}

export default EnquireButton